.results {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: white;
  position: relative;
}

.result-row {
  justify-content: center !important;
  padding: 0 10%;
}

.result-row .col-md-4.col-6 {
  padding: 0 !important;
}

.result-row p {
  font-size: 1rem;
  margin-bottom: 0;
}

.results-col {
  border-radius: 10px;
  border: 1px solid var(--color-dark-text);
  padding: 5px;
  margin: 5px;
}

.results-col .fas {
  margin-right: 5px;
}

@media (max-width: 1028px) {
  .results {
    padding: 35px 7px 20px;
  }

  .result-row {
    padding: 0 2%;
  }

  .result-row .col-md-4.col-6 {
    padding: 2px !important;
  }

  .results-col {
    margin: 0 !important;
  }

  .result-row p {
    font-size: 0.8rem !important;
  }
}