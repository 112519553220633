footer {
  margin: auto auto 0;
  text-align: center;
  width: 97%;
}

footer .ki-footer {
  padding: 10px 0;
  width: 30px;
}

footer p {
  color: var(--color-dark-text);
}

@media (max-width: 1028px) {
  footer {
    padding: 0 10px !important;
    position: relative;
    z-index: 1;
  }

  footer .ki-footer {
    height: 50px;
    padding: 10px 0 !important;
  }
}