.title-row {
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title-btns-container {
  padding: 0;
}

.title-row .col-5 {
  padding: 0;
}

.title-btns {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-btns p {
  margin-bottom: 0;
  font-size: 1rem;
}

.container-quantity-filter .col-6 {
  padding-right: 0;
  width: 45%;
}

.title-btn {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: var(--box-shadow);
  margin-left: 10px !important;
}

.dr-filter,
.dr-filter:focus {
  border: none !important;
  box-shadow: none !important;
  height: 24px !important;
  text-align: center;
  padding: 0 0.75rem !important;
}

.search-input {
  height: 44px !important;
  border-radius: 10px;
  border: none;
  box-shadow: var(--box-shadow);
}

.search-label {
  padding: 10px 12px !important;
  font-size: 0.8rem;
}

.container-quantity-filter {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 0;
  height: 44px !important;
}

.quantity-filter {
  max-width: 100% !important;
  display: flex;
}

@media (max-width: 1028px) {
  .title-btns-container {
    width: 100% !important;
  }

  .title-btns {
    margin-bottom: 10px;
  }

  .title-btns p {
    font-size: 0.8rem !important;
  }

  .dr-filter,
  .dr-filter:focus {
    height: 100% !important;
    font-size: 0.8rem !important;
  }
}