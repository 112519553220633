.activate {
  position: relative;
  background: var(--screen-background-gradient);
  min-height: 100vh;
  color: var(--color-dark-text);
  text-align: center;
}

.loading {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin: auto;
}

.loading-animation {
  display: flex;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
}

.loading-text {
  display: block;
  width: 100%;
  font-weight: 400 !important;
}

.activate .main-btn {
  margin: 10px auto 0 !important;
  width: 50%;
}

.expired {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center !important;
  flex-wrap: wrap;
  margin: auto;
}

.expired-text {
  margin: auto;
}