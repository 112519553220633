.preferences.not-admin {
  text-align: left;
}

.preferences h1,
.preferences h4 {
  text-align: left;
  color: var(--color-dark-text);
}

.preferences .not-admin {
  padding-left: 5px;
}

.preferences.form .row {
  max-width: 100% !important;
  margin: auto;
  align-items: flex-start;
}

.preferences.form .row .col-md-6 {
  padding: 0 !important;
}

.preferences .main-btn {
  width: 150px;
  margin: 10px 0 0 5px;
}