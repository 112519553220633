.quantity-filter p {
  white-space: nowrap;
  overflow: hidden;
}

.quantity-filter i {
  margin-right: 5px;
}

@media (max-width: 1028px) {
  .popover-btn {
    font-size: 0.7rem;
    margin: auto !important;
  }
}