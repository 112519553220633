.patients .col-md-9.title-col-1 {
  display: flex;
  margin: auto;
}

.patients .col-md-3.title-col-2 button.btn {
  margin: 0 !important;
}

.patients-num {
  color: var(--color-dark-text);
  margin-bottom: 0 !important;
}

.row.patient-row.d-flex {
  margin-bottom: 0 !important;
  justify-content: space-between;
}

.row.patient-row.d-flex .col-5 {
  padding: 0 !important;
}