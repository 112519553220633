.usage-title {
  display: flex;
  justify-content: space-between;
}

.filter-btn {
  text-align: end;
}

p.usage-description {
  font-size: 1.2rem;
  margin-bottom: 0;
  text-align: start;
  padding-left: 12px;
}

.loading-container {
  margin-top: 30px;
}

p.no-info {
  padding: 10px 12px;
  text-align: left;
}

.total-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
}

.total-txt {
  font-size: 1.2rem;
  margin: 0;
  text-align: start;
}

.total-value {
  text-align: end;
}

.total {
  font-weight: 600;
}

.row.usage-container-row {
  margin: 20px 0 !important;
  color: var(--color-dark-text);
}

.usage-section-title {
  text-align: start;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 20px 0;
}

.usage-section-title i {
  padding-right: 10px;
}

.usage-container hr {
  margin: 20px auto;
  width: 97%;
}

.totals hr {
  margin: 10px auto !important;
}

.user-management {
  padding: 12px 10px 5px 10px;
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
}

.user-management .base-container {
  box-shadow: none;
}

.button-bar {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin: 10px 0;
}

.button-bar h6 {
  align-self: center;
  margin-right: auto;
  margin-bottom: 0;
  font-weight: bold;
}

.button-bar button {
  padding: 10px 10px;
  width: 80px;
  color: white;
  border: none;
  border-radius: 10px;
  background-color: #304044;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
}

.button-bar button.active {
  background-color: #8db5e0;
  color: white;
}

.button-bar button:hover {
  background-color: #6099a6;
}

.button-bar-disabled {
  background-color: #d3d3d3 !important;
  color: black !important;
  cursor: not-allowed !important;
}

.result-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px !important;
  background: #FFFFFF !important;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.result-count {
  font-size: 1rem;
  margin-left: 10px;
  align-self: center;
}

@media (max-width: 768px) {
  .result-count-container {
    padding: 3px;
  }

  .result-count {
    font-size: 0.8rem;
  }

  .button-bar {
    gap: 3px;
  }

  .button-bar button {
    padding: 8px 8px;
    font-size: 0.7rem;
  }

  .button-bar h6 {
    font-size: 0.8rem;
  }
}