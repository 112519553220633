/* || Login Screen */

.login {
  position: relative;
  background: var(--screen-background-gradient);
  min-height: 100vh;
  color: var(--color-dark-text);
  text-align: center;
}

.login-container {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
}

.login-container h2 {
  font-weight: 600;
  margin-bottom: 10px;
}

.login-form {
  width: 40%;
  margin: auto;
  font-size: 1rem;
  padding: 3%;
  color: var(--color-dark-text);
  position: relative;
  z-index: 2;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow-v3);
}

.form .form-floating {
  padding: 0 5px;
  margin: 5px 0;
}

.form .form-floating input,
.form .form-floating select {
  border-radius: 10px;
}

.form.login-form .main-btn {
  margin: 10px 0 !important;
  width: 50%;
}

.form.login-form a {
  font-size: 0.9rem !important;
}

.password-link {
  color: var(--color-orange)
}

.password-link:hover {
  color: var(--color-orange-hover) !important;
}

@media (max-width: 1028px) {
  .login-form {
    width: 90%;
    padding: 7% 5%;
  }

  .login-form .main-btn {
    margin-bottom: 10px;
  }

  .password-link {
    font-size: 0.8rem;
  }
}