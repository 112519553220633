.popover {
  border-radius: 15px !important;
  box-shadow: var(--box-shadow);
  border: none;
}

.download-btn {
  position: absolute;
  background-color: var(--color-dark-text);
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 1rem;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  top: 10px;
  right: 10px;
}

.download-btn:hover {
  background-color: var(--color-dark-text-hover);
  color: white !important;
}

.download-btn:hover>i {
  color: white !important;
}