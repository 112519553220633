.cards-container {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  max-width: 100%;
  margin: auto;
  padding: 10px 0 0;
}

.card-container {
  padding: 0;
}

.add-card-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
}

.add-card-btn .main-btn {
  margin: 0;
}

/* ------------- Mobile Styles ------------- */

@media (max-width: 1028px) {
  .add-card-btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .cards-container {
    justify-content: center !important;
  }
}