/* || Legal Checkbox */

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25%;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid var(--color-border);
  border-radius: 30%;
}

.checkbox-container:hover input~.checkmark {
  background-color: rgb(235, 233, 233);
}

.checkbox-container input:checked~.checkmark {
  background-color: var(--color-dark-text);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  top: 6px;
  left: 9px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.legal-checkbox.checkbox-container {
  margin: 20px auto 10px;
  padding-left: 7% !important;
}

.legal-checkbox a {
  color: var(--color-orange);
}

.legal-checkbox a:hover {
  color: var(--color-orange-hover) !important;
}

@media (max-width: 1028px) {
  .legal-checkbox.checkbox-container {
    padding-left: 10% !important;
  }
}