.login-form p.step-info {
  font-size: 0.8rem;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0;
}

.signin-step-2 .row {
  max-width: 100%;
  margin: 10px auto;
}

.signin-step-2 .row .col-md-6 {
  margin: 0 auto;
  padding: 0 10px;
}

.signin-step-2 .row .col-md-6 label {
  padding-left: 20px;
}

.form-title {
  margin-bottom: 30px;
}

.login-link {
  color: var(--color-orange);
}

.login-link:hover {
  color: var(--color-orange-hover) !important;
}

.password-input {
  position: relative;
}

i.far.fa-eye.password-visibility,
.fas.fa-eye-slash.password-visibility {
  position: absolute;
  top: 35%;
  right: 5%;
  color: #7f7f7f;
  z-index: 5;
}

i.far.fa-eye.password-visibility:hover,
.fas.fa-eye-slash.password-visibility:hover {
  color: var(--color-orange);
  cursor: pointer;
}

@media (max-width: 1028px) {
  .form-title {
    margin-bottom: 10px;
  }
}