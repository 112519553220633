/* || Main Screen */

.profile {
  position: relative;
  background: var(--screen-background-gradient);
  min-height: 100vh;
  color: var(--color-dark-text);
  text-align: center;
}

.profile .information-container {
  width: 100%;
  margin: auto;
}

.profile .information-container .form h4 {
  padding: 10px 5px !important;
}

.ki-logo {
  width: 32px;
  height: 32px;
  display: flex;
  margin: 0 10px 40px;
}

.users-container,
.add-card-container .profile-form-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.users .row {
  margin: auto;
  width: 100% !important;
  align-items: flex-start;
}

.users-container .row {
  margin-bottom: 15px !important;
}

.users .row .title-col-1,
.add-card-container .row .title-col-1 {
  text-align: left;
  padding-right: 50px;
  padding-left: 0 !important;
}

.users .row .title-col-1 p {
  font-size: 0.9rem !important;
}

.users .row .title-col-2 {
  text-align: right;
  padding-right: 0 !important;
}

.title-col-2 .main-btn {
  width: 100%;
}

@media (max-width: 1028px) {
  .users-container .title-col-1 {
    padding-right: 0 !important;
  }

  .users-container .title-col-1 p {
    font-size: 0.8rem !important;
    text-align: center;
  }

  .users-container .title-col-2 {
    margin: 10px auto !important;
    width: 70%;
  }

  .add-card-container .profile-form-container {
    padding: 10px;
  }

  .add-card-container .row .title-col-1 {
    text-align: center;
    padding: inherit;
  }
}