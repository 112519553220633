/* || Main Screen */

.main {
  position: relative;
  background: var(--screen-background-gradient-v2);
  min-height: 100vh;
  color: var(--color-dark-text);
  text-align: center;
}

.bg-color-2 {
  background: var(--screen-background-gradient);
}

.main-container {
  min-height: 100% !important;
}

.main-container h4 {
  font-weight: 700;
  font-size: 1.2rem;
}

.main-container p {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  letter-spacing: 1px;
}

/* Main images */

.main1 {
  width: 20vw;
  position: absolute;
  right: 0;
  bottom: 0;
}

.main2 {
  width: 25vw;
  position: absolute;
  left: 0;
  top: 0;
}

/* Main container row */

.row.main-container-row {
  margin: 0;
  padding: 0;
  width: 100% !important;
  display: flex;
  align-content: stretch !important;
  align-items: stretch;
}

/* Information */

.information {
  min-height: 100vh !important;
  padding-right: 50px;
}

.information .row {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: 0 !important;
}

.information-container {
  padding: 20px 5%;
}

.information-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 20px 0;
  text-align: left;
}

.success-msg,
.error-msg {
  font-size: 1rem;
  margin: 20px 0 0;
}

.send-btn {
  width: 170px;
}

.main-btn-content {
  width: 100%;
  margin: 0 auto;
}

.main-btn-content .col-2,
.main-btn-content .col-10 p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.back-btn {
  border: none;
  background-color: transparent;
  text-align: start;
  display: block;
  color: var(--color-dark-text);
}

.back-btn i.fas.fa-arrow-left {
  font-size: 1.5rem;
  color: var(--color-dark-text);
  margin-right: 10px;
}

@media (max-width: 1028px) {
  .information {
    padding: 0 10px !important;
  }

  .information-title {
    font-size: 1.2rem;
  }

  .main-container h4 {
    font-size: 1rem;
  }
}