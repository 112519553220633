.step .form-select {
  margin: 10px auto;
  font-size: 1rem;
}

.step .react-datepicker-wrapper {
  width: 100%;
}

.drag-drop-container {
  background-color: var(--main-bg-color);
  border-radius: 5px;
  margin-bottom: 10px;
}

p.file-name {
  font-size: 1rem !important;
}

.center {
  margin: auto;
}

.error-msg {
  color: red;
}

.step {
  box-shadow: var(--box-shadow);
  background-color: white;
  margin: 8px auto;
  border-radius: 35px;
  padding: 5%;
  margin-bottom: 20px;
  position: relative;
}

.step-circle {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #D0D7E7;
}

.step-circle p {
  color: var(--color-dark-text);
  font-size: 1.2rem !important;
  font-weight: 700;
}

.step-txt {
  margin: 5px 0 10px !important;
  font-size: 1rem;
}