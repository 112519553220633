.update-patients-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 60%;
}

.update-patients-container .btn {
  margin: 0 auto;
  width: 100%;
}