.ant-select {
  color: green;
}

.ant-select-selector {
  padding: 10px 5px !important;
}

.ant-select-selector,
.ant-select-selection-item {
  color: var(--color-dark-text);
}