.profile-content-btn {
  display: block;
  margin: 5px 0;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  color: var(--color-dark-text)
}

.profile-content-btn.selected {
  background-color: var(--color-light-grey);
  box-shadow: none;
}