.password-form {
  width: 50%;
  text-align: left;
}

.password-form .main-btn {
  width: 150px;
  margin: 10px 0 0 5px;
}

@media (max-width: 1028px) {
  .password-form {
    width: 100%;
  }
}