.filter-table-dropdown {
  position: relative;
  display: flex;
  left: 0;
  top: 0;
}

.filter-table-dropdown .dropdown-table-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  max-height: 35vh;
}

.filter-table-dropdown .dropdown-table-menu div {
  padding: 8px;
  display: flex;
}

.filter-table-dropdown .dropdown-table-menu div:hover {
  background-color: #f1f1f1;
}

.form-check-label {
  font-size: 0.7rem;
  text-align: left;
  margin-left: 7px;
}

@media (max-width: 1028px) {
  .filter-table-dropdown .dropdown-table-menu {
    min-width: 100px;
    max-height: 50px;
  }
}