.add-card-form {
  text-align: left;
}

.card-form-input {
  padding: 5px 20px !important;
}

.form-control div {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.add-card-form .card-input input {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  max-width: 100% !important;
}

input#cardNumber {
  margin-left: 10px !important;
  width: 15em !important;
}