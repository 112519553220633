.profile-btn {
  margin-top: auto;
}

.dropdown {
  position: relative;
  bottom: 10px;
  display: inline-block;
  width: 100% !important;
}

.dropbtn {
  text-align: left;
  width: 100% !important;
  padding: 10px 20px;
  border: 3px solid transparent;
  background: white;
  border-radius: 10px;
}

.user-icon {
  width: 18px;
  margin-right: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  bottom: 101%;
  right: 0;
  width: 100%;
  z-index: 4 !important;
}

.dropdown-container {
  background-color: white;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.dropdown-content a,
.dropdown-content button {
  color: var(--color-dark-text);
  padding: 12px 16px !important;
  text-decoration: none;
  display: block;
  background: none !important;
  border: 0;
  width: 100%;
  text-align: left;
  z-index: 5 !important;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  color: var(--color-orange) !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn,
.register-header:hover {
  box-shadow: var(--box-shadow);
  background-color: var(--color-light-grey);
}

@media (max-width: 1028px) {
  .dropbtn {
    color: var(--color-dark-text);
  }
}