.cards-card {
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  box-shadow: var(--box-shadow);
}

.card-radio-btn .radio-container {
  margin-bottom: 0;
  height: 22px;
}

.card-radio-btn .radio-btn {
  left: 0;
  top: 0;
}

.payment-card {
  margin: 10px 0;
  box-shadow: var(--box-shadow);
}

.card-radio-btn,
.card-data,
.card-info {
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
}

.card-brand-img {
  padding: 0;
}

.card-brand-img .brand-img {
  max-width: 100%;
  max-height: 100%;
}

.card-main-info,
.card-dates {
  padding: 0;
  text-align: start;
}


.card-action {
  padding: 0;
  text-align: end;
}

.card-action .delete-btn {
  padding: 0;
}

.payment-card .card-action {
  text-align: center !important;
  min-height: 22px;
}

@media (max-width: 1028px) {
  .card-data p {
    font-size: 0.8rem;
  }

  .card-container .cards-card {
    margin: 10px 0;
  }

  .card-number p {
    font-size: 0.7rem;
  }
}