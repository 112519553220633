/* || Radio Button */

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  width: 22px;
  background-color: var(--color-primary-white);
  border: 1px solid var(--color-unselected-border);
  border-radius: 50%;
}

.radio-container:hover input~.radio-btn {
  border: 1px solid var(--color-primary-blue);
}

.radio-container input:checked~.radio-btn {
  background-color: var(--color-primary-white);
  border: 1px solid var(--color-primary-blue);
}

.radio-btn:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-container input:checked~.radio-btn:after {
  display: block;
}

.radio-container .radio-btn:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-primary-blue);
}