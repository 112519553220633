/* || Error Screen */

.error-container {
  background: var(--screen-background-gradient);
  text-align: center;
  position: relative;
  min-height: 100vh;
  color: var(--color-dark-text);
}

.error-content {
  min-height: 90vh !important;
  display: flex;
  flex-direction: column;
}

.error-content .row {
  margin: auto;
  padding: 3% 10%;
  position: relative;
  max-width: 100%;
}

.error-img {
  width: 300px;
  padding-right: 10%;
}

.error-txt {
  text-align: left;
  padding-right: 10% !important;
  margin: auto !important;
  position: relative;
  z-index: 3;
}

.error-content h1 {
  color: var(--color-dark-text);
  font-weight: 700;
  font-size: 3rem;
}

.error-content .error-txt p {
  color: var(--color-dark-text);
  font-weight: 400;
  font-size: 1.2rem;
}

.error-content .filter-button {
  margin: 0;
}

@media (max-width: 1028px) {
  /* m| Error Screen */

  .error-container .row {
    padding: 5% 7%;
    margin-left: 0 !important;
    max-width: 100%;
  }

  .error-img {
    padding-left: 20%;
    width: 250px;
  }

  .error-txt {
    text-align: center;
    padding: 10% !important;
  }

  .error-container h1 {
    font-size: 2.5rem;
  }

  .error-container .filter-button {
    margin: 2%;
  }

  .error-container footer {
    position: inherit;
  }
}