.info-btn {
  position: absolute;
  background-color: transparent;
  border: 2px solid var(--color-dark-text);
  border-radius: 25px;
  color: var(--color-dark-text);
  font-size: 0.8rem;
  padding: 4px 10px;
  text-align: left;
  text-decoration: none;
  top: 10px;
  right: 10px;
}

@media (max-width: 1028px) {
  .info-btn {
    border: 1.5px solid var(--color-dark-text);
    font-size: 0.7rem;
    padding: 3px 9px;
    top: 5px;
    right: 5px;
  }
}