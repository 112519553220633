.users-container .scroll {
  max-height: 550px;
}

@media (max-width: 1028px) {
  .users-container td select {
    font-size: 0.8rem !important;
  }

  td button.main-btn {
    min-width: 90px !important;
    font-size: 0.8rem !important;
  }
}