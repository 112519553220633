.row.dr-row {
  margin-bottom: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.dr-row .col-4 {
  padding: 0 5px !important;
}

.dr-btn {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 25px !important;
  margin: 0 !important;
  text-align: center;
}

.dr-btn i {
  position: absolute;
  top: 25%;
  right: 4px;
  width: 20px;
}

.dr-btn:hover i {
  font-size: 1rem !important;
}