/* Instructions */

.col-md-3.menu {
  min-height: 100vh;
  padding: 0 50px 0 0 !important;
}

.instructions-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background-color: white;
  min-height: 100vh !important;
  height: 100%;
  width: 100%;
  margin: 0 !important;
}

.instructions-container p {
  font-size: 1rem;
  padding: 0;
  margin-bottom: 0;
}

.row.instructions {
  height: 100% !important;
}

.instructions {
  padding: 30px 20px 0;
  text-align: center;
}

.instructions-title {
  text-align: left;
  margin: 0 0 20px 10px;
}

@media (max-width: 1028px) {
  .col-md-3.menu {
    padding: 0 !important;
    background-color: white;
  }
}