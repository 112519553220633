.legal-checkbox p {
  text-align: start;
}

/* Modal */

.card-modal .modal-content {
  border-radius: 20px !important;
  padding: 2%;
  /* background-color: var(--color-secondary-blue-v1) !important; */
  border: none !important;
}

.card-modal .modal-header {
  border-bottom: none !important;
  justify-content: center;
  position: relative;
  padding-bottom: 0;
}

.card-modal .modal-title,
.card-modal .modal-body p {
  text-align: center;
}

.card-modal .modal-title {
  text-align: center;
  padding: 1% 5% 0;
  font-size: 1rem;
  font-weight: 600;
}

.card-modal .modal-title hr {
  width: 85%;
  margin: 15px auto;
}

.card-modal .btn-close {
  position: absolute;
  top: 7px;
  right: 7px;
}

.card-modal .modal-body {
  padding-top: 0;
  text-align: start;
}

.card-modal .legal-checkbox p {
  text-align: start !important;
}